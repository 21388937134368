import { Stippet } from "./components/Stippet";
import { List } from "./components/List";
import { Header } from "./Header";
import { useEffect, useState } from "react";
import { StippetCreator } from "./StippetCreator";
import { StippetsArea } from "./StippetsArea";

export default function App() {
  const [currentStippets, setCurrentStippets] = useState(() => {
    const stippets = localStorage.getItem("stippets");
    return stippets ? JSON.parse(stippets) : [];
  });

  function handleCreateNewStippet(newStippetObject) {
    setCurrentStippets((currentStippets) => [
      newStippetObject,
      ...currentStippets,
    ]);
  }

  useEffect(
    function () {
      localStorage.setItem("stippets", JSON.stringify(currentStippets));
    },
    [currentStippets]
  );

  return (
    <>
      <Header></Header>
      <main>
        <div className="test"></div>
        <StippetsArea>
          <StippetCreator onCreate={handleCreateNewStippet} />
          {currentStippets.length > 0 && (
            <List className="stippets-list">
              {currentStippets.map((stippet) => (
                <Stippet
                  title={stippet.title}
                  body={stippet.body}
                  key={stippet.id}
                />
              ))}
            </List>
          )}
        </StippetsArea>
      </main>
    </>
  );
}
