import { useState, useRef, useEffect } from "react";
import { Button } from "./components/Button";

export function StippetCreator({ onCreate }) {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const titleRef = useRef(null);

  useEffect(
    function () {
      if (isOpen) {
        titleRef.current.focus();
      }
    },
    [isOpen]
  );

  function handleCreatorPanelToggle(event) {
    event.preventDefault();
    setIsOpen((prevIsOpen) => !prevIsOpen);
    setBody("");
    setTitle("");
  }

  function saveStory(event) {
    event.preventDefault();

    if (!title || !body) return;

    const newStory = {
      id: crypto.randomUUID(),
      title: title,
      body: body,
    };

    onCreate(newStory);
    setBody("");
    setTitle("");
    setIsOpen(false);
  }

  return isOpen ? (
    <form onSubmit={saveStory} className="stippet-creator">
      <Button
        type="button"
        className="clse-btn"
        onClick={handleCreatorPanelToggle}
      >
        X
      </Button>
      <input
        className="nw-stippet-title"
        type="text"
        placeholder="Title"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        ref={titleRef}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
      ></input>
      <textarea
        className="nw-stippet-body"
        placeholder="Tell your tale..."
        value={body}
        onChange={(event) => setBody(event.target.value)}
        rows={20}
      ></textarea>
      <Button className="submit-btn" type="submit">
        Submit!
      </Button>
    </form>
  ) : (
    <button className="nw-stippet-button" onClick={handleCreatorPanelToggle}>
      Add new Story +
    </button>
  );
}
