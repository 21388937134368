import { useState } from "react";
import { Button } from "./Button";

export function Stippet({ title = "", body = "" }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const bodyWords = body.split(" ");
  const isTruncated = bodyWords.length > 40;

  const truncatedText = isTruncated
    ? `${bodyWords.slice(0, 40).join(" ")}...`
    : body;

  const handleExpandedToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="stippet">
      <h2>{title}</h2>
      <p>{isExpanded ? body : truncatedText}</p>
      {isTruncated && (
        <Button onClick={handleExpandedToggle}>
          {isExpanded ? "Close" : "Read"}
        </Button>
      )}
    </div>
  );
}
