export function Button({
  onClick,
  children,
  style = { border: "none", fontSize: "1em" },
  className = "",
}) {
  return (
    <button style={style} onClick={onClick} className={className}>
      {children}
    </button>
  );
}
